/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

html, body {
  overflow-x: hidden;
}

/* Efeito de brilho desaparecendo e reaparecendo da esquerda para a direita */
.text-brilhante {
  position: relative;
  display: inline-block;
  font-weight: bold;
  color: white;
  /* font-size: 1.25rem; */
  margin-left: 6px;
  overflow: hidden; /* Garante que o brilho só será visível dentro do elemento */
}

.text-brilhante::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)); /* Efeito de brilho */
  animation: brilho 3s ease-in-out infinite; /* Animação para o brilho */
  z-index: 1;
}

.text-brilhante span {
  position: relative;
  z-index: 2;
}

@keyframes brilho {
  0% {
    left: -100%; /* Começa fora à esquerda */
    opacity: 0;
  }
  50% {
    left: 100%; /* Move para a direita */
    opacity: 1;
  }
  100% {
    left: -100%; /* Volta para a esquerda */
    opacity: 0;
  }
}

.collection-modal .slick-arrow.slick-next:before,
.collection-modal .slick-arrow.slick-prev:before {
  color: #d70060; /* Cor do ponto inativo */
  font-size: 30px !important;
}

.collection-modal .slick-prev {
  left: 4px !important; /* Alinha à esquerda */
}

.collection-modal .slick-next {
    right: 12px !important; /* Alinha à direita */
}

@media (max-width: 720px) {
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }

  .slick-prev {
    left: 10px !important; /* Alinha à esquerda */
  }

  .slick-next {
    right: 10px !important; /* Alinha à direita */
  }

  .collection-modal .slick-arrow.slick-next:before,
  .collection-modal .slick-arrow.slick-prev:before {  
    display: none;
  }
}

.tutorial-force {
  margin-left: 0 !important;
}